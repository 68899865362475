<template>
  <div
    class="cursor-pointer relative flex items-center p-5"
    :class="{
      'border-b border-gray-200 dark:border-dark-5': index + 1,
      'bg-gray-200': isOpened
    }"
  >
    <div
      v-if="isOrder"
      class="w-12 h-6 flex items-center justify-center absolute top-5 text-xs text-red-500 bg-theme-31 rounded-full bg-primary font-medium -mt-1 -mr-1"
      :class="isRTL ? 'left-5' : 'right-5'"
    >
      {{ isRTL ? 'طلب' : 'Order' }}
    </div>
    <div class="overflow-hidden w-full">
      <div class="flex items-center gap-2">
        <app-list-item-image
          :value="imageUrl"
          type="user"
        ></app-list-item-image>
        <div>
          <div class="font-medium mb-2">
            {{ userName }}
            <span class="text-xs text-gray-400 mx-2" v-if="isTyping"
              >Typing...</span
            >
          </div>
          <div class="text-xs text-theme-10 whitespace-normal">
            ID: {{ conversation.id }}
          </div>
          <div
            class="truncate text-gray-600 dark:text-gray-300 mt-0.5 w-full whitespace-normal"
          >
            {{ conversation.description }}
          </div>
        </div>
      </div>
      <div
        class="text-xs text-gray-500"
        :class="isRTL ? 'mr-auto text-left' : 'ml-auto text-right'"
      >
        <app-show-date-time
          :dateTime="conversation['createdAt']"
          date-class="notification-date"
          :key="isRTL"
        ></app-show-date-time>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import FirebaseRepository from '@/shared/firebase/firebase-repository'

export default {
  name: 'app-chat-side-menu-item',
  props: {
    index: {
      type: Number,
      default: 0
    },
    conversation: {
      type: Object
    },
    openedComplaintId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      imageUrl: null,
      userName: null
    }
  },
  watch: {
    creator: {
      handler(newVal, oldVal) {
        if (newVal?.avatar !== oldVal?.avatar && newVal) {
          this.imageUrl = newVal.avatar
        }
        if (newVal?.fullName !== oldVal?.fullName && newVal) {
          this.userName = newVal.fullName
        }
      },
      deep: true, // Enable deep watching
      immediate: true // Trigger the watcher immediately on component initialization
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      userTyping: 'complaintStore/isTyping',
      typingList: 'complaintStore/typingList'
    }),
    isOpened() {
      return this.conversation.id === this.openedComplaintId
    },
    isOrder() {
      return !!this.conversation.isOrder
    },
    isTyping() {
      // if (this.userTyping) this.scrollBottom()
      return this.typingList.includes(this.conversation.id)
    },
    creator() {
      return this.conversation.creator
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style lang="scss"></style>
