<template>
  <!-- BEGIN: Modal Content -->
  <div id="delete-saved-reply-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="modal-title">
            <app-i18n code="savedReplies.delete.title"></app-i18n>
          </h2>
          <button type="button" data-dismiss="modal" class="header-close-button" @click="doClose()">
            <span>
              <svg viewBox="0 0 8 8" fill="currentColor" width="8" height="8" data-hook="close-medium">
                <path d="M7.2 0L4 3.2 0.8 0 0.1 0.7 3.3 4 0 7.3 0.7 8 4 4.7 7.3 8 8 7.3 4.7 4 7.9 0.7z"></path>
              </svg>
            </span>
          </button>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            {{ i18n('savedReplies.delete.hint') }}
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-end items-center">
          <AppButton
            id="submit-btn"
            type="button"
            class="btn-submit btn bg-theme-24 text-white cursor-pointer"
            :class="!loading ? 'w-24' : ''"
            :disabled="loading"
            :loading="loading"
            @click="doSubmit()"
          >
            <strong>{{ i18n('common.delete') }}</strong>
            <template v-slot:loading>
              <span>{{ i18n('common.loading') }}</span>
              <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
            </template>
          </AppButton>
          <button type="button" data-dismiss="modal" class="btn-cancel btn bg-theme-32 text-black w-24 ml-1 mr-1"
            :disabled="loading" @click="doClose()">
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import Message from '@/shared/message/toastify'

export default defineComponent({
  name: 'delete-saved-reply-modal',
  props: {
    id: {
      type: String
    }
  },
  setup() {
    const hideModal = () => {
      cash('#delete-saved-reply-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  data() {
    return {
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      loading: 'reply/destroy/loading'
    })
  },
  methods: {
    ...mapActions({
      doDestroy: 'reply/destroy/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doClose() {
      this.$emit('close')
    },
    async doSubmit() {
      await this.doDestroy(this.id)
      this.doClose()
      this.hideModal()
    }
  }
})
</script>

<!-- Modal Dialog Style -->
<style lang="scss" scoped>
.modal .modal-dialog {
  box-shadow: 0 8px 8px 0 rgb(22 45 61 / 12%), 0 3px 24px 0 rgb(22 45 61 / 18%);
  border-radius: 15px 15px 8px 8px;
  background-color: #fff;
}
.modal-content {
  border-radius: 15px 15px 8px 8px;
}
@media (min-width: 640px) {
  .modal .modal-dialog {
    width: 600px;
  }
}
</style>

<!-- Modal Header Style -->
<style lang="scss" scoped>
.modal-header {
  background: #ee5951;
  min-height: 60px;
  padding: 18px;
  padding-left: 30px;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  line-height: 24px;
}
.modal-title {
  display: inline-block;
  width: calc(100% - 40px);
  word-break: break-word;
}
.header-close-button {
  width: 24px;
  height: 24px;
  background-color: rgba(22,45,61,.24);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 18px;
  border-color: transparent;
  transition-duration: 100ms;
  transition-timing-function: linear;
  transition-property: background-color, color, border-color;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.header-close-button:hover {
  background-color: rgba(22,45,61,.3);
  color: #fff;
}
.header-close-button span{
  line-height: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<!-- Modal Body Style -->
<style lang="scss" scoped>
.modal-body {
  padding: 36px 30px 12px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: #162d3d;
}
.form-label {
  color: #32536A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap!important;
  display: inline-block!important;
}
.form-input:hover {
  background-color: #EAF7FF;
}

.infoicon-tooltip {
  position: relative;
  display: inline;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.infoicon-tooltip div {
  box-sizing: border-box;
}
.infoicon-tooltip div svg {
  color: #3899EC;
  vertical-align: middle;
}
</style>

<!-- Modal Footer Style -->
<style lang="scss" scoped>
.modal-footer {
  border-top: none !important;
  padding: 23px 30px 30px;
}
.btn-submit {
  color: #FFFFFF;
  background-color: #ee5951;
  border-color: #ee5951;
}
.btn-submit:hover {
  color: #FFFFFF;
  background-color: #f66;
  border-color: #f66;
}
.btn-submit:disabled {
  color: #FFFFFF;
  background-color: rgba(22,45,61,.3);
  border-color: transparent;
}
.btn-cancel {
  background-color: transparent !important;
  border-color: #ee5951 !important;
  color: #ee5951 !important;
}
.btn-cancel:hover {
  background-color: #f66 !important;
  border-color: #f66 !important;
  color: #FFFFFF !important;
}
</style>
