<template>
  <Transition>
    <div
      class="flex items-center border-t border-gray-200 dark:border-dark-5 px-4 pt-1 relative"
      v-if="show"
    >
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="saved-replies-container">
        <div
          class="saved-reply-item"
          v-for="(reply, index) in replies"
          :key="index"
        >
          <div
            class="saved-reply-message cursor-pointer truncate"
            @click="useThis(reply.message)"
          >
            {{ reply.message }}
          </div>
          <div class="saved-reply-title">{{ reply.title }}</div>

          <div class="saved-reply-actions">
            <div class="_5Sury flex items-center">
              <div
                style="overflow-x: hidden"
                :style="isRTL ? 'margin-left: auto;' : 'margin-right: auto;'"
              >
                <button
                  type="button"
                  class="actions-button btn_select-saved-reply"
                  style="max-width: 100%"
                  @click="useThis(reply.message)"
                >
                  <span>{{ i18n('savedReplies.useThis') }}</span>
                </button>
              </div>
              <div class="flex items-center gap-2">
                <button
                  id="edit-saved-reply"
                  type="button"
                  class="actions-button btn_other-actions"
                  @click="showFormModal(reply)"
                >
                  <span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="18px"
                      height="18px"
                    >
                      <path
                        d="M16.679,5.60187506 L18.381,7.30587506 C19.207,8.13287506 19.207,9.47787506 18.381,10.3058751 L10.211,18.4858751 L4,19.9998751 L5.512,13.7818751 L13.682,5.60187506 C14.481,4.79987506 15.878,4.79887506 16.679,5.60187506 Z M8.66091072,16.0462125 L9.973,17.3598751 L15.625,11.7018751 L12.289,8.36087506 L6.637,14.0198751 L7.95422762,15.3386821 L11.1467061,12.1463747 C11.3419735,11.9511178 11.6585559,11.9511262 11.8538129,12.1463936 C12.0490698,12.341661 12.0490613,12.6582435 11.8537939,12.8535004 L8.66091072,16.0462125 Z M16.306,11.0198751 L17.7,9.62387506 C18.15,9.17287506 18.15,8.43787506 17.7,7.98687506 L15.998,6.28287506 C15.561,5.84587506 14.801,5.84687506 14.364,6.28287506 L12.97,7.67887506 L16.306,11.0198751 Z M5.426,18.5738751 L8.995,17.7438751 L6.254,14.9988751 L5.426,18.5738751 Z"
                      ></path>
                    </svg>
                  </span>
                </button>
                <button
                  id="delete-saved-reply"
                  type="button"
                  class="actions-button btn_other-actions"
                  @click="showDeleteModal(reply.id)"
                >
                  <span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="18px"
                      height="18px"
                    >
                      <path
                        d="M17,17 C17,18.6568542 15.6568542,20 14,20 L9,20 C7.34314575,20 6,18.6568542 6,17 L6,7 L5,7 L5,6 L18,6 L18,7 L17,7 L17,17 Z M9,9 L10,9 L10,16 L9,16 L9,9 Z M11,9 L12,9 L12,16 L11,16 L11,9 Z M13,9 L14,9 L14,16 L13,16 L13,9 Z M7,17 C7,18.1045695 7.8954305,19 9,19 L14,19 C15.1045695,19 16,18.1045695 16,17 L16,7 L7,7 L7,17 Z M13,6 L13,5 L10,5 L10,6 L9,6 L9,5 C9,4.44771525 9.44771525,4 10,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,6 L13,6 Z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <button class="create-saved-reply" @click="showFormModal(null)">
          <!-- <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview" @click="selectedId = row.id"
            class="text-theme-10 flex items-center gap-2 whitespace-nowrap"
            > -->
          <!-- <plus-icon size="6.3x" class=""></plus-icon> -->
          <svg
            viewBox="0 0 63 63"
            fill="currentColor"
            width="63"
            height="63"
            class="sP5FYjL"
          >
            <path d="M31 31V0h1v31h31v1H32v31h-1V32H0v-1z"></path>
          </svg>
          <app-i18n
            code="savedReplies.create.button"
            style="font-size: 16px; line-height: 24px; padding-top: 12px"
          ></app-i18n>
          <!-- </a> -->
        </button>
      </div>
      <saved-reply-form-modal
        ref="modal"
        :record="selectedReply"
        @close="selectedReply = null"
      ></saved-reply-form-modal>
      <delete-saved-reply-modal :id="selectedId"></delete-saved-reply-modal>
    </div>
  </Transition>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import SavedReplyFormModal from '@/views/complaint/saved-reply-form-modal.vue'
import DeleteSavedReplyModal from '@/views/complaint/delete-saved-reply-modal.vue'

export default defineComponent({
  name: 'app-saved-replies',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    input: {
      type: String
    }
  },
  components: {
    [SavedReplyFormModal.name]: SavedReplyFormModal,
    [DeleteSavedReplyModal.name]: DeleteSavedReplyModal
  },
  data() {
    return {
      selectedId: null,
      selectedReply: null
      // replies: [
      //   {
      //     id: 1,
      //     title: 'Pre-Chat Form Response 1',
      //     message: '[1] Hey FIRST NAME, thanks for getting in touch. Our team will contact you soon, let us know what we can do for you.'
      //   },
      //   {
      //     id: 2,
      //     title: 'Pre-Chat Form Response 2',
      //     message: '[2] Hey FIRST NAME, thanks for getting in touch. Our team will contact you soon, let us know what we can do for you.'
      //   }
      // ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      currentUser: 'auth/currentUser',
      replies: 'reply/list/rows',
      loading: 'reply/list/loading'
    })
  },
  async mounted() {
    await this.doFetch()
  },
  methods: {
    ...mapActions({
      doFetch: 'reply/list/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    showFormModal(reply) {
      this.selectedReply = reply
      cash('#saved-reply-form-modal').modal('show')
    },
    showDeleteModal(id) {
      this.selectedId = id
      cash('#delete-saved-reply-modal').modal('show')
    },
    useThis(message) {
      // this.selectedReply = this.selectedReply.length
      //   ? this.selectedReply + '\n' + message
      //   : message
      const messageInput = this.input.length
        ? this.input + '\n' + message
        : message
      this.$emit('select', messageInput)
      this.$emit('close')
    }
  }
})
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  height: 211px;
  transition: height 0.2s cubic-bezier(0.39, 0.16, 0.34, 0.99);
}
.v-enter-from,
.v-leave-to {
  height: 0;
}
.saved-replies-container {
  width: 100%;
  height: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
}
.saved-reply-item {
  max-width: 240px;
  min-width: 240px;
  height: 180px;
  border: 1px solid #dfe5eb;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 17px 18px 22px;
  box-sizing: border-box;
  // cursor: pointer !important;
}
.saved-reply-message {
  font-size: 14px;
  line-height: 1.29;
  color: #162d3d;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 90px;
  text-align: left;
}
.saved-reply-title {
  font-size: 12px;
  line-height: 1.25;
  color: #7a92a5;
}
.create-saved-reply {
  padding: 30px;
  color: #142E71;
  border: 1px dashed #142E71;
  // color: #3899ec;
  // border: 1px dashed #4eb7f5;
  border-radius: 0.5rem;
  box-sizing: border-box;
  max-width: 240px;
  min-width: 240px;
  height: 180px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-saved-reply:hover {
  // background-color: #f18b4236;
  background-color: #dbdffa;
}
</style>

<style lang="scss" scoped>
$font_family: 'Helvetica Neue', 'Helvetica', 'Arial', '\30E1\30A4\30EA\30AA',
  'meiryo', '\30D2\30E9\30AE\30CE\89D2\30B4 pro w3', 'hiragino kaku gothic pro',
  'sans-serif';
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #142e71;
$primary_color_hover: #dbdff9;

.saved-reply-actions {
  display: none;
}
.saved-reply-item:hover .saved-reply-actions {
  display: block;
}
.saved-reply-item:hover .saved-reply-title {
  display: none;
}

.actions-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn_select-saved-reply {
  color: #ffffff;
  background-color: $primary_color;
  border: 1px solid $primary_color;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  outline: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  transition-duration: 100ms;
  transition-timing-function: linear;
  transition-property: background-color, color, border-color;
  height: 30px;
  min-width: 72px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: $font_family;
  border-radius: 15px;
  padding: 0 17px;
}
.btn_select-saved-reply:hover {
  background-color: $primary_color_hover;
  border-color: $primary_color_hover;
  color: #ffffff;
}
.btn_other-actions {
  border-radius: 18px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  outline: 0;
  transition-duration: 100ms;
  transition-timing-function: linear;
  transition-property: background-color, color, border-color;
  width: 30px;
  height: 30px;
  border: 1px solid $primary_color;
  background: 0 0;
  color: $primary_color;
}
.btn_other-actions:hover {
  color: #fff;
  border-color: transparent;
  background-color: $primary_color_hover;
}
</style>
