<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="saved-reply-form-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="modal-title">
            <app-i18n
              code="savedReplies.create.title"
              v-if="!isEditing"
            ></app-i18n>
            <app-i18n
              code="savedReplies.update.title"
              v-if="isEditing"
            ></app-i18n>
          </h2>
          <button
            type="button"
            data-dismiss="modal"
            class="header-close-button"
            @click="doReset()"
          >
            <span>
              <svg
                viewBox="0 0 8 8"
                fill="currentColor"
                width="8"
                height="8"
                data-hook="close-medium"
              >
                <path
                  d="M7.2 0L4 3.2 0.8 0 0.1 0.7 3.3 4 0 7.3 0.7 8 4 4.7 7.3 8 8 7.3 4.7 4 7.9 0.7z"
                ></path>
              </svg>
            </span>
          </button>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <div class="flex items-center gap-1">
              <label for="" class="form-label">{{
                i18n('savedReplies.fields.title-label')
              }}</label>
              <div class="infoicon-tooltip">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width="24"
                    height="24"
                    class="infoicon-svg"
                  >
                    <path
                      d="M13,15 L13,10 L11,10 L10,12 L11,12 L11,15 L10,16 L10,17 L14,17 L14,16 L13,15 Z M11,9 L13,9 L13,7 L11,7 L11,9 Z M12,20 C7.589,20 4,16.411 4,12 C4,7.589 7.589,4 12,4 C16.411,4 20,7.589 20,12 C20,16.411 16.411,20 12,20 L12,20 Z M12,3 C7.029,3 3,7.029 3,12 C3,16.971 7.029,21 12,21 C16.971,21 21,16.971 21,12 C21,7.029 16.971,3 12,3 L12,3 Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <input
              v-model="model.title"
              class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
              :placeholder="i18n('savedReplies.placeholder.title')"
            />
          </div>
          <div class="col-span-12">
            <label for="" class="form-label">{{
              i18n('savedReplies.fields.message-label')
            }}</label>
            <textarea
              v-model="model.message"
              :rows="6"
              class="form-input form-control w-full dark:bg-dark-9 dark:placeholder-white"
              :placeholder="i18n('savedReplies.placeholder.message')"
            ></textarea>
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center">
          <AppButton
            id="submit-btn"
            type="button"
            class="btn-submit btn bg-theme-24 text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading || !model.message"
            :loading="saveLoading"
            @click="doSubmit()"
          >
            <strong v-if="isEditing">{{ i18n('common.save') }}</strong>
            <strong v-else>{{ i18n('common.create') }}</strong>
            <template v-slot:loading>
              <span>{{ i18n('common.loading') }}</span>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton>
          <button
            type="button"
            data-dismiss="modal"
            class="btn-cancel btn bg-theme-31 text-black w-24 ml-1 mr-1"
            :disabled="saveLoading"
            @click="doReset()"
          >
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default defineComponent({
  name: 'saved-reply-form-modal',
  props: {
    record: {
      type: Object
    }
  },
  setup() {
    const hideModal = () => {
      cash('#saved-reply-form-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  watch: {
    record(newVal) {
      if (newVal) {
        this.model = newVal
      } else {
        this.model = {
          id: null,
          title: null,
          message: null
        }
      }
    }
  },
  data() {
    return {
      model: {
        id: null,
        title: null,
        message: null
      },
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'reply/form/saveLoading'
    }),
    isEditing() {
      return !!this.record
    }
  },
  methods: {
    ...mapActions({
      doCreate: 'reply/form/doCreate',
      doUpdate: 'reply/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (!this.model.message) {
        this.errorMessage = 'Please add a message'
        return false
      }

      return true
    },
    doReset() {
      this.model = {
        id: null,
        title: null,
        message: null
      }
      this.$emit('close')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { id, ...values } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values
        })
      } else {
        await this.doCreate(values)
      }
      this.doReset()
      this.hideModal()
    }
  }
})
</script>

<!-- Modal Dialog Style -->
<style lang="scss" scoped>
.modal .modal-dialog {
  box-shadow: 0 8px 8px 0 rgb(22 45 61 / 12%), 0 3px 24px 0 rgb(22 45 61 / 18%);
  border-radius: 15px 15px 8px 8px;
  background-color: #fff;
}
.modal-content {
  border-radius: 15px 15px 8px 8px;
}
@media (min-width: 640px) {
  .modal .modal-dialog {
    width: 600px;
  }
}
</style>

<!-- Modal Header Style -->
<style lang="scss" scoped>
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #142e71;
$primary_color_hover: #dbdff9;

.modal-header {
  background: $primary_color;
  min-height: 60px;
  padding: 18px;
  padding-left: 30px;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  line-height: 24px;
}
.modal-title {
  display: inline-block;
  width: calc(100% - 40px);
  word-break: break-word;
}
.header-close-button {
  width: 24px;
  height: 24px;
  background-color: rgba(22, 45, 61, 0.24);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 18px;
  border-color: transparent;
  transition-duration: 100ms;
  transition-timing-function: linear;
  transition-property: background-color, color, border-color;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.header-close-button:hover {
  background-color: rgba(22, 45, 61, 0.3);
  color: #fff;
}
.header-close-button span {
  line-height: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<!-- Modal Body Style -->
<style lang="scss" scoped>
.modal-body {
  padding: 36px 30px 12px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: #162d3d;
}
.form-label {
  color: #32536a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap !important;
  display: inline-block !important;
}
.form-input:hover {
  // background-color: #EAF7FF;
  background-color: #dbdff9;
}
.infoicon-tooltip {
  position: relative;
  display: inline;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.infoicon-tooltip div {
  box-sizing: border-box;
}
.infoicon-tooltip div svg {
  color: #3899ec;
  vertical-align: middle;
}
</style>

<!-- Modal Footer Style -->
<style lang="scss" scoped>
// $primary_color: #3899EC;
// $primary_color_hover: #4EB7F5;
$primary_color: #142e71;
$primary_color_hover: #dbdff9;

.modal-footer {
  border-top: none !important;
  padding: 23px 30px 30px;
}
.btn-submit {
  color: #ffffff;
  background-color: $primary_color;
  border-color: $primary_color;
}
.btn-submit:hover {
  background-color: $primary_color_hover;
  border-color: $primary_color_hover;
  color: #ffffff;
}
.btn-submit:disabled {
  color: #ffffff;
  background-color: rgba(22, 45, 61, 0.3);
  border-color: transparent;
}
.btn-cancel {
  background-color: transparent !important;
  border-color: $primary_color !important;
  color: $primary_color !important;
}
.btn-cancel:hover {
  background-color: $primary_color_hover !important;
  border-color: $primary_color_hover !important;
  color: #ffffff !important;
}
</style>
